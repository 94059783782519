import { Alert, Paper, Slide, Snackbar } from "@mui/material";
import React, { useEffect } from "react";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }  

function InfoSnackbar({message, open, onClose}) {
  
    return (
          <Paper mt={3}>
            <Snackbar
              open={open}
              onClose={onClose}
              autoHideDuration={1500}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={<span id="message-id">{message}</span>}
              >
              </Snackbar>
          </Paper>
    );
  }
  

  export default InfoSnackbar;
