import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Typography,
  Snackbar,
} from "@mui/material";
import { Box, spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { ThirtyFpsSelect } from "@mui/icons-material";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {

  const navigate = useNavigate();
  const { signIn } = useAuth();
  // const [loginSuccessful, setLoginSuccessful] = React.useState(false);
  const {state} = useLocation();
  const [snackbar, setSnackbar] = React.useState(state?.openSnackbar);
  

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {

          // do a call 
          await signIn(values.email, values.password);

          const userRole = window.localStorage.getItem("userRole");
          if (userRole != null && userRole != undefined) {
            if (userRole == "1") {
              navigate('/user')
            } else {
              navigate('/customer')
            }
          }
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <Box
            display="flex"
            justifyContent='space-between'
          >
            {/* <Box
              sx={{
                flexDirection: 'row'
              }}
            > */}
            <FormControlLabel
              align="left"
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {/* </Box> */}
            {/* <Box
              sx={{
                flexDirection: 'row-reverse'
              }}
            > */}
            <Button
              align="right"
              component={Link}
              to="/auth/reset-password"
              color="primary"
            >
              Forgot password?
            </Button>
            {/* </Box> */}
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign in
          </Button>
          <Button
            component={Link}
            to="/auth/sign-in"
            fullWidth
            color="primary"
          >
            Admin Login
          </Button>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>Don't have an account?</Typography>
            <Button
              component={Link}
              to="/auth/sign-up"
              color="primary"
            >
              Sign Up
            </Button>
          </Box>
          <Snackbar
            open={snackbar}
            autoHideDuration={6000}
            onClose={() => { setSnackbar(false) }}
            message="Sign Up successful, Sign In to continue"
          />
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
