import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axios from "./axios";
import axios1 from "axios";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};


const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios1.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
    delete axios1.defaults.headers.common.Authorization;
  }
};

const setUserInSession = (user) => {
  if (user) {
    localStorage.setItem("userRole", user.userRole);
    localStorage.setItem("userId", user._id);
    localStorage.setItem("userEmail", user.email);
    localStorage.setItem("userFirstName", user.firstName);
    localStorage.setItem("userLastName", user.lastName);
    localStorage.setItem("userAddress", user.address);
    localStorage.setItem("userPhoneNumber", user.phoneNumber);
  } else {
    localStorage.removeItem("userRole");
    localStorage.removeItem("userId");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userFirstName");
    localStorage.removeItem("userLastName");
    localStorage.removeItem("userAddress");
    localStorage.removeItem("userPhoneNumber");
  }
};


export { verify, sign, isValidToken, setSession, setUserInSession };
