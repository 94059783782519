import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Slide,
  Card,
  CardContent,
  Alert,
  CircularProgress,
} from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  Delete,
  DoNotDisturb,
  Edit,
  FilterList as FilterListIcon,
  PlayArrow
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import InfoSnackbar from "../components/InfoSnackbar";
import { Formik } from "formik";
import * as Yup from "yup";
import { BASE_PATH, CUSTOMER, SIGNUP, FIND_ALL, SUSPEND, ALLOW, UPDATE, DELETE, CREATE, DELETE_BY_IDS } from "../../constants/apiRoutes";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";


const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.shipped && green[500]};
  background: ${(props) => props.processing && orange[700]};
  background: ${(props) => props.cancelled && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "firstName", alignment: "center", label: "First Name" },
  { id: "lastName", alignment: "center", label: "Last Name" },
  { id: "email", alignment: "center", label: "Email" },
  { id: "phoneNumber", alignment: "center", label: "Phone Number" },
  { id: "address", alignment: "center", label: "Address" },
  { id: "actions", alignment: "center", label: "Actions" },
];


const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  address: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
});

function AddForm({ stayOpen , refreshList, setRefreshList, openSnackbar, snackbarMessage }) {
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await axios.post(BASE_PATH + CUSTOMER + CREATE, values)
        .then(function (response) {
          console.log(response);
          stayOpen(false)
          setRefreshList(!refreshList);
          snackbarMessage(response.data.message);
          openSnackbar(true);
        })
        .catch(function (error) {
          console.log(error);
          stayOpen(false)
        });
    } catch (error) {
      console.log(error);
      stayOpen(false)
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        phoneNumber: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6} >
                  <Grid item md={6}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>
                <Divider my={6} />
                <TextField
                  name="email"
                  label="Email"
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  variant="outlined"

                  p={10}
                />
                <Divider my={6} />
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  fullWidth
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />
                <Divider my={6} />
                <TextField
                  name="address"
                  label="Address"
                  error={Boolean(
                    touched.address && errors.address
                  )}
                  fullWidth
                  helperText={touched.address && errors.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />
                <Divider my={2} />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Save
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function EditForm({ formData, stayOpen, refreshList, setRefreshList, openSnackbar, snackbarMessage }) {

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      values._id = formData._id;
      await axios.post(BASE_PATH + CUSTOMER + UPDATE, values)
        .then(function (response) {
          console.log(response);
          stayOpen(undefined)
          setRefreshList(!refreshList);
          snackbarMessage(response.data.message);
          openSnackbar(true);
        })
        .catch(function (error) {
          console.log(error);
          stayOpen(undefined)
        });
    } catch (error) {
      console.log(error);
      stayOpen(undefined)
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        address: formData.address,
        phoneNumber: formData.phoneNumber,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6} >
                  <Grid item md={6}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      value={values.firstName}
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      value={values.lastName}
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>
                <Divider my={6} />
                <TextField
                  name="email"
                  label="Email"
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  value={values.email}
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  variant="outlined"

                  p={10}
                />
                <Divider my={6} />
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  fullWidth
                  value={values.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />
                <Divider my={6} />
                <TextField
                  name="address"
                  label="Address"
                  error={Boolean(
                    touched.address && errors.address
                  )}
                  fullWidth
                  value={values.address}
                  helperText={touched.address && errors.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />
                <Divider my={2} />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Update
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected, selected, refreshList, setRefreshList, openSnackbar, snackbarMessage } = props;
  const handleBulkOperation = async () => {
    try {
      await axios.post(BASE_PATH + CUSTOMER + DELETE_BY_IDS, selected)
        .then(function (response) {
          console.log(response);
          setRefreshList(!refreshList);
          snackbarMessage(response.data.message);
          openSnackbar(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Customers
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large" onClick={() => handleBulkOperation()}>
              <Delete />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar >
  );
};

function EnhancedTable({refreshList, setRefreshList, openSnackbar, snackbarMessage}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openEditDialog, setOpenEditDialog] = React.useState(undefined);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, [refreshList]);

  const fetchCustomers = () => {
    axios.get(BASE_PATH + CUSTOMER + FIND_ALL)
      .then(function (response) {
        console.log(response);
        setRows(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setRows([]);
      });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDelete = async (id) => {
    try {
      await axios.delete(BASE_PATH + CUSTOMER + DELETE + id)
        .then(function (response) {
          setOpenDeleteDialog(false);
          setRefreshList(!refreshList);
          snackbarMessage(response.data.message);
          openSnackbar(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} selected={selected} refreshList={refreshList} setRefreshList={setRefreshList} openSnackbar={openSnackbar}
                  snackbarMessage={snackbarMessage} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row._id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row._id)}
                        />
                      </TableCell>
                      <TableCell align="center">{row.firstName}</TableCell>
                      <TableCell align="center">{row.lastName}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.phoneNumber}</TableCell>
                      <TableCell align="center">{row.address}</TableCell>
                      <TableCell padding="none" align="right">
                        <Box mr={2}>
                          <IconButton aria-label="delete" size="large" onClick={() => { setOpenEditDialog(row._id) }}>
                            <Edit />
                          </IconButton>
                          <Dialog
                            open={openEditDialog == row._id}
                            onClose={() => setOpenEditDialog(undefined)}
                            aria-labelledby="form-dialog-title"
                          >
                            <DialogTitle id="form-dialog-title">Edit Customers</DialogTitle>
                            <DialogContent>
                              <EditForm formData={row} stayOpen={setOpenEditDialog} refreshList={refreshList} setRefreshList={setRefreshList} openSnackbar={openSnackbar}
                      snackbarMessage={snackbarMessage}/>
                            </DialogContent>
                          </Dialog>
                          <IconButton aria-label="details" size="large" onClick={() => { setOpenDeleteDialog(true) }}>
                            <Delete />
                          </IconButton>
                          <DeleteConfirmationDialog value={row._id} open={openDeleteDialog} stayOpen={setOpenDeleteDialog} handleConfirmation={() => handleDelete(row._id)} />                          
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function CustomerList() {
  const [openAddDialog, setOpenAddDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [refreshList, setRefreshList] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  return (
    <React.Fragment>
      <Helmet title="Customers" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Customers
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Customers</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenAddDialog(true)}
            >
              <AddIcon />
              New Customer
            </Button>
            <Dialog
              open={openAddDialog}
              onClose={() => setOpenAddDialog(false)}
              aria-labelledby="form-dialog-title"
              maxWidth="xl"
            >
              <DialogTitle id="form-dialog-title">Add Customer</DialogTitle>
              <DialogContent>
                <AddForm
                  stayOpen={setOpenAddDialog}
                  refreshList={refreshList} 
                  setRefreshList={setRefreshList} 
                  openSnackbar={setOpenSnackbar}
                  snackbarMessage={setSnackbarMessage}
                />
              </DialogContent>
            </Dialog>
            <InfoSnackbar message={snackbarMessage} open={openSnackbar} onClose={() => setOpenSnackbar(false)} />
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable refreshList={refreshList} setRefreshList={setRefreshList}  openSnackbar={setOpenSnackbar}
                  snackbarMessage={setSnackbarMessage} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CustomerList;
