import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import { isValidToken, setSession, setUserInSession } from "../utils/jwt";
import { BASE_PATH, FORGOT_PASSWORD, LOGIN, SIGNUP, USER } from "../constants/apiRoutes";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { ErrorRounded } from "@mui/icons-material";

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.js` file.

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const userRole = window.localStorage.getItem("userRole");
  let hasRole = false;
  if(userRole != null && userRole != undefined && children.props.match.route.roles != null && children.props.match.route.roles != undefined && children.props.match.route.roles.length > 0){
    for (let index = 0; index < children.props.match.route.roles.length; index++) {
      const element = children.props.match.route.roles[index];
      if(element == userRole){
        hasRole = true;
      }
    }
    if (!hasRole){
      navigate('/404')
    }
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user : null,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
      const payload = {
        email: email,
        password: password,
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      };

      await fetch(BASE_PATH + USER + LOGIN, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }else {
            throw { message : "Invalid Email or Password"}
          }
        })
        .then(data => {
          setSession(data.token);
          setUserInSession(data.data)
          dispatch({
            type: SIGN_IN,
            payload: { user: data.data },
          });
        });
  };

  const signOut = async () => {
    setSession(null);
    setUserInSession(null)
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password, firstName, lastName, address, phoneNumber) => {
    const payload =  {
      email,
      password,
      firstName,
      lastName,
      address,
      phoneNumber,
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };
    await fetch(BASE_PATH + USER + SIGNUP, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }else {
            throw { message : "Could not sign up, email already in use."}
          }
        }).then(data => { console.log(data)});
  };

  const resetPassword = async(email) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    await fetch(BASE_PATH + USER + FORGOT_PASSWORD + email, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }else {
        throw {message : "Could not send reset email"}
      }
    })

  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
