import axios from "axios";

const axiosInstance = axios.create();

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )
// );

axiosInstance.interceptors.request.use(function (config) {
  config.headers.common = {
    Authorization: window.localStorage.getItem("accessToken"),
  };
  return config;
  })
axiosInstance.defaults.baseURL = "http://localhost:5001/";

export default axiosInstance;

