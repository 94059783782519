import styled from "@emotion/styled";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import {
    Button,
    Alert as MuiAlert,
    TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import { BASE_PATH, UPDATE_PASSWORD, USER } from "../../constants/apiRoutes";
import { useNavigate } from "react-router-dom";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function UpdatePasswordForm({ token }) {

    const [severity, setSeverity] = React.useState("")
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = React.useState();

    return (
        <Formik
            initialValues={{
                password: "",
                confirmPassword: "",
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string()
                    .required("password is required"),
                confirmPassword: Yup.string().oneOf([Yup.ref('password')])
                    .required("confirmPassword is required and should match"),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                let payload = {
                    token: token,
                    newPassword: values.password
                }
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload)
                };
                await fetch(BASE_PATH + USER + UPDATE_PASSWORD, requestOptions)
                    .then(response => {
                        if (response.ok) {
                            setSeverity("success")
                            setStatus({ success: true });
                            setErrors({ submit: "Your password is updated" });
                            setSubmitting(false);
                            setIsSubmitted(true);
                            navigate("/auth/sign-in")
                        } else {
                            setSeverity("error")
                            setStatus({ success: false });
                            setErrors({ submit: "Could not update your password" });
                            setSubmitting(false);
                            setIsSubmitted(false);
                        }
                    })
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (

                <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                        <Alert mt={2} mb={1} severity={severity}>
                            {errors.submit}
                        </Alert>
                    )}{
                        !isSubmitted ?
                            <div>
                                <TextField
                                    type="password"
                                    name="password"
                                    label="Passwrod"
                                    value={values.password}
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    my={3}
                                    disabled={isSubmitted}
                                />
                                <TextField
                                    type="password"
                                    name="confirmPassword"
                                    label="Re-type Password"
                                    value={values.confirmPassword}
                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                    fullWidth
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    my={3}
                                    disabled={isSubmitted}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitted}
                                >
                                    Reset password
                                </Button>
                            </div>
                            : null}
                </form>
            )}
        </Formik>
    );
}

export default UpdatePasswordForm;
