export const BASE_PATH = "https://ez-dealer-be-566e2f294057.herokuapp.com/";
export const USER = "user/";
export const CUSTOMER = "customer/";
export const VEHICLE = "vehicle/";
export const CREATE = "create/";
export const UPDATE = "update/";
export const DELETE = "delete/";
export const DELETE_BY_IDS = "deleteByIds/";
export const FIND_ALL = "findAll/";
export const FIND_BY_ID = "findById/";
export const SIGNUP = "signup/";
export const LOGIN = "login/";
export const FORGOT_PASSWORD = "forgotPassword/";
export const SUSPEND = "suspend";
export const ALLOW = "allow";
export const UPDATE_PASSWORD = "updatePassword";
