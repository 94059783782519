import { CarRental, Person, Person2 } from "@mui/icons-material";
import {

  Users,
  Settings,
} from "react-feather";

const pagesSection = [
  {
    href: "/user",
    icon: Person,
    title: "Users",
    roles: ["1"],
  },
  {
    href: "/customer",
    icon: Person2,
    title: "Customers",
    roles: ["1","2"],
  },
  {
    href: "/vehicle",
    icon: CarRental,
    title: "Vehicle",
    roles: ["1","2"],
  },
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
    roles: ["1","2"],
  },
  {
    href: "/auth",
    icon: Users,
    title: "Auth",
    roles: ["1","2"],
    children: [
      {
        href: "/auth/sign-in",
        title: "Sign In",
        roles: ["1","2"],
      },
      {
        href: "/auth/sign-up",
        title: "Sign Up",
        roles: ["1","2"],
      },
      {
        href: "/auth/reset-password",
        title: "Reset Password",
        roles: ["1","2"],
      },
      {
        href: "/auth/404",
        title: "404 Page",
        roles: ["1","2"],
      },
      {
        href: "/auth/500",
        title: "500 Page",
        roles: ["1","2"],
      },
    ],
  },
];

const navItems = [
  {
    title: "Dashboard",
    pages: pagesSection,
  },
];

export default navItems;
