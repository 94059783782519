import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { spacing } from "@mui/system";
import InfoSnackbar from "../components/InfoSnackbar";
import axios from "axios";
import { BASE_PATH, UPDATE , USER} from "../../constants/apiRoutes";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function Public() {

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Public info
        </Typography>

        <Grid container spacing={6}>
          <Grid item md={8}>
            <TextField
              id="username"
              label="Username"
              defaultValue="lucylavender"
              variant="outlined"
              fullWidth
              my={2}
            />

            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                label="Biography"
                id="biography"
                multiline={true}
                rows={3}
                maxRows={4}
                variant="outlined"
                defaultValue="Lucy is a Freelance Writer and Social Media Manager who helps finance professionals and Fin-tech startups build an audience and get more paying clients online."
              />
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <CenteredContent>
              <BigAvatar
                alt="Remy Sharp"
                src="/static/img/avatars/avatar-1.jpg"
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" color="primary" component="span">
                  <CloudUpload mr={2} /> Upload
                </Button>

                <Typography variant="caption" display="block" gutterBottom>
                  For best results, use an image at least 128px by 128px in .jpg
                  format
                </Typography>
              </label>
            </CenteredContent>
          </Grid>
        </Grid>

        <Button variant="contained" color="primary">
          Save changes
        </Button>
      </CardContent>
    </Card>
  );
}

function Private() {

  const [firstName, setFirstName] = React.useState(window.localStorage.getItem("userFirstName"));
  const [lastName, setLastName] = React.useState(window.localStorage.getItem("userLastName"));
  const [address, setaddress] = React.useState(window.localStorage.getItem("userAddress"));
  const [phoneNumber, setPhoneNuber] = React.useState(window.localStorage.getItem("userPhoneNumber"));
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const email  = window.localStorage.getItem("userEmail");
  const id  = window.localStorage.getItem("userId");

  const handleSubmit = async() => {
    const payload  = {
      _id : id,
      firstName : firstName,
      lastName : lastName,
      address: address,
      phoneNumber: phoneNumber
    }
    await axios.post(BASE_PATH + USER + UPDATE, payload)
        .then(function (response) {
          setOpenSnackbar(true);
          localStorage.setItem("userFirstName", firstName);
          localStorage.setItem("userLastName", lastName);
          localStorage.setItem("userAddress", address);
          localStorage.setItem("userPhoneNumber", phoneNumber);
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  const handleFirstNameChange = async(event) => {
    setFirstName(event.target.value);
  }
  const handleLastNameChange = async(event) => {
    setLastName(event.target.value);
  }
  const handlePhoneNumberChange = async(event) => {
    setPhoneNuber(event.target.value);
  }
  const handleAddressChange = async(event) => {
    setaddress(event.target.value);
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Private info
        </Typography>

        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              id="first-name"
              label="First name"
              variant="outlined"
              value={firstName}
              fullWidth
              my={2}
              onChange={handleFirstNameChange}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="last-name"
              label="Last name"
              variant="outlined"
              value={lastName}
              fullWidth
              my={2}
              onChange={handleLastNameChange}
            />
          </Grid>
        </Grid>

        <TextField
          id="email"
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          fullWidth
          my={2}
          disabled={true}
        />

        <TextField
          id="address"
          label="Address"
          variant="outlined"
          value={address}
          fullWidth
          my={2}
          onChange={handleAddressChange}
        />

        <TextField
          id="address"
          label="PhoneNumber"
          variant="outlined"
          value={phoneNumber}
          fullWidth
          my={2}
          onChange={handlePhoneNumberChange}
        />

        <Button variant="contained" color="primary" mt={3} onClick={handleSubmit}>
          Save changes
        </Button>
      </CardContent>
      <InfoSnackbar message="Profile Updated" open={openSnackbar} onClose={() => setOpenSnackbar(false)} />
    </Card>
  );
}

function Settings() {
  return (
    <React.Fragment>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Settings
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Settings</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Private />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Settings;
